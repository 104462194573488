.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  margin: 40px 80px;
}

.form-group {
  margin-top: 30px;
}

#form-submit {
  margin-top: 30px;
}

.result {
  padding: 15px;
  overflow: hidden;
  background-color: rgb(236, 236, 236);
  border-radius: 12px;
}

.result button {
  float: right;
}

mark { 
  background-color: rgb(236, 236, 236);
  margin-left: 5px;
  margin-right: 15px;
  font: 1rem Inconsolata, monospace;
}

.result-text {
  font: 1rem Inconsolata, monospace;
}

.nav-description {
  float: right;
}

.column {
  margin-top: 12px;
}

.actions-form {
  margin-top: 12px;
}

.footer {
  display:flex; 
  flex-direction:row;
}

#github {
  width: auto;  
  height: 22px;  
  float: right;
  margin-right: 20px;
}

.footer-text {
  flex: 1;
  
}
